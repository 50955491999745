import type { FC } from 'react';
import { Grid, H2, H5  } from '#ui';
import MarkdownText from '@components/MarkdownText';
import type { CopyContentProps as Props } from './types';
import { getSpacing } from '@lib/utils';

const CopyContent: FC<Props> = ({ text, heading, superheading, order }) => (
    <Grid 
    as="section"
    sx={{
        textAlign: 'center',
        marginBlockStart: getSpacing(order)
      }}>
        {!!superheading?.length && 
          <H5 as='small'>{superheading}</H5>}
        {!!heading?.length && (
          <H2
            col={[null, '3/-3']}
            sx={{ marginBlockStart: 0 }}
          >
            {heading}
          </H2>
        )}
        {!!text?.length && 
          <MarkdownText>{text}</MarkdownText>}
    </Grid>
);

export default CopyContent;
